
@tailwind base;
@tailwind components;
@tailwind utilities;


::-webkit-scrollbar {
  width: 0px
}

.titlebar {
  -webkit-user-select: none;
  -webkit-app-region: drag;
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: var(--mauve11);
}
[data-highlighted] > .RightSlot {
  color: white;
}
[data-disabled] .RightSlot {
  color: var(--mauve8);
}

.trans {
  transition-duration: .15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}


.sidebar-transition {
  transition-property: all;
  transition-timing-function: ease;
  transition-duration: 400ms;
}

.sidebar-transition:hover {
  @apply text-purple-800;
}

.context-menu-shadow {
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.fade {
    -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  max-height: 40rem;
  width: 100%;
}

.faded {
    -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
  max-height: 30rem;
  width: 100%;
}

.damn:hover {
    box-shadow: 1px 1px 15px -3px rgba(0,0,0,0.4);
    transition: 0.3s;
}

.damns:hover {
    box-shadow: 1px 1px 15px -3px rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 9999px;
    background-color: white;
}

.imaage img {
    @apply rounded-lg mt-6 w-full
}

.imaage a {
    @apply text-[#117fb3]
}

.imaage p {
    @apply mb-4
}

figcaption {
    @apply text-sm
}

figure {
    @apply mb-6 flex flex-col
}

.imaage ul {
    @apply list-disc ml-8 mb-4
}

.imaage:first-child {
    width: 60%;
}

.imaage h1 {
    font-weight: 500;
    font-size: 1.25rem;
}

.imaage h2 {
    font-weight: 700;
    font-size: 1.25rem;
}

.fuck {
    box-shadow: 1px 10px 100px -20px rgba(237, 115, 15, 0.595);
}

.fucks {
    box-shadow: 1px 1px 50px -3px rgba(0,0,0,0.9);
}

.search-shadow {
    box-shadow: 1px 1px 10px -3px rgba(0,0,0,0.1);
}

.list-stack {
  box-shadow: 1px 1px 50px -30px rgba(0,0,0,0.2);
} 

.fucker {
    box-shadow: 1px 1px 100px -3px rgba(0,0,0,0.2);
}

.holy:hover {
    box-shadow: 11px 8px 25px 10px rgba(0,0,0,0.1);
    transition: 0.5s;
}

.holy:focus-within {
    box-shadow: 11px 8px 25px 10px rgba(0,0,0,0.1);
    transition: 0.5s;
}


.shit {
    box-shadow: 0 0 0 2px #b8c3d3;
    transition: 0.3s
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeInColour {
    from {
      opacity: 0;    
    }
    to {
      opacity: 0.5;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }


  .sidebar {
    animation: fadeIn 200ms ease-out;
  }

  .sidebar-colour {
    animation: fadeInColour 200ms ease-out;
  }
  
  .DialogOverlay[data-state='open'],
  .DialogContent[data-state='open'],
  .ContextMenuContent[data-state='open'] {
    animation: fadeIn 200ms ease-out;
  }
  
  .DialogOverlay[data-state='closed'],
  .DialogContent[data-state='closed'],
  .ContextMenuContent[data-state='closed'] {
    animation: fadeOut 200ms ease-in;
  }
  
  .cardPage {
    animation: grow 600ms ease-out;
    transition-delay: 1s;
  }

  @keyframes grow {
    from {
      transform: scale(0.999);
      opacity: 0;
    }
    to {
      transfrom: scale(0.9995);
      opacity: 1;
    }
  }

::placeholder {
    color: #d5dae0
}

body {
  font-family: Nunito;
}